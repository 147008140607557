<template>
    <v-banner v-if="setupStage != null" :class="bannerClass" icon="mdi-run-fast">
        <template v-slot>
            <p class="font-weight-bold text-h6 my-auto">Speedy Start!</p>
        </template>
        <template v-slot:actions>
            <v-btn text @click="backStep" small>Back</v-btn>
            <v-btn class="secondary" @click="nextStep" small>Next!</v-btn>
        </template>
    </v-banner>
</template>

<script>
export default {
    name: 'BT-Fast-Start-Banner',
    data() {
        return {
            setupStage: null
        }
    },
    props: {
        bannerClass: {
            type: String,
            default: 'primary'
        }
    },
    mounted() {
        var r = this.$route.query.setup || null;
        if (r != null) {
            this.setupStage = Number.parseInt(r);
        }
    },
    methods: {
        backStep() {
            this.$router.push({ name: 'setup', query: { setup: this.setupStage }});
        },
        nextStep() {
            this.$router.push({ name: 'setup', query: { setup: this.setupStage + 1 }});
        }
    }
}
</script>